import React, { Component } from 'react'
import Doc from './Policy.pdf'
import './Policy.css'

class Policy extends Component {

    componentDidMount() {
        document.title = 'OOO «ЭЛОДЖИН» - Политика конфиденциальности приложения «ALODGING» - alodging.by'
        document.querySelector('body').scrollIntoView()
    }

    render() {
        return (
            <div className="Policy">
                <div>
                    <i className="far fa-file-pdf"></i>
                    <a href={Doc} target="_blank" rel="noopener noreferrer">Скачать</a>
                </div>
                <h1>Политика Общества с ограниченной ответственностью «Элоджин» в отношении обработки персональных данных в приложении «ALodging»</h1>
                <p>Дата опубликования текущей версии: 01 июля 2020 года.</p>
                <h2>1. Общие положения</h2>
                <p>1.1. Настоящий документ (далее – Политика) определяет порядок сбора, использования, раскрытия, передачи и хранения персональных данных Обществом с ограниченной ответственностью «Элоджин», обрабатываемых в приложении «ALodging», а также меры по обеспечению безопасности таких персональных данных с целью защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
                <p>1.2. Для целей Политики используются следующие основные понятия:</p>
                <p>1.2.1. Приложение – программное обеспечение «ALodging», предназначенное для работы на персональных компьютерах, смартфонах, планшетах и других мобильных устройствах (вне зависимости от операционной системы или платформы), подключенных к информационно-телекоммуникационной сети «Интернет».</p>
                <p>1.2.2. Мессенджеры – приложения, позволяющие отправлять сообщения (Viber, WattsApp, Telegram и др.), в которых содержатся тематические группы, чаты и сообщества, посвященные Приложению, администрируемые Оператором.</p>
                <p>1.2.3. Оператор – Общество с ограниченной ответственностью «Элоджин», являющееся юридическим лицом по законодательству Республики Беларусь, зарегистрированное Минским городским исполнительным комитетом 22 апреля 2019 года, УНП 193244728, место нахождения: 220040, Республика Беларусь, город Минск, улица Некрасова, дом 88, помещение 1-5, которому принадлежат права на Приложение и которое организует и (или) осуществляет обработку персональных данных в Приложении и мессенджерах.</p>
                <p>1.2.4. Пользователь – юридическое лицо и (или) индивидуальный предприниматель, зарегистрированные по законодательству Республики Беларусь или Российской Федерации, а также гражданин Республики Беларусь или Российской Федерации, которому исполнилось 18 лет, которые осуществляют либо намерены осуществлять деятельность по предоставлению жилых помещений для проживания третьим лицам на возмездной основе, и которые прошли авторизацию в Приложении.</p>
                <p>1.2.5. Персональные данные - любая информация, относящаяся к прямо или косвенно определённому или определяемому на основании такой информации физическому лицу (субъекту персональных данных).</p>
                <p>1.2.6. Персональные данные Пользователя – любая информация, относящаяся к прямо или косвенно определённому или определяемому на основании такой информации Пользователю – физическому лицу.</p>
                <p>1.2.7. Персональные данные третьих лиц – любая информация, относящаяся к прямо или косвенном определённому или определяемому на основании такой информации физическому лицу, не являющемуся Пользователем.</p>
                <p>1.3. Действие Политики распространяется на все операции, совершаемые Оператором с персональными данными с использованием средств автоматизации или без их использования.</p>
                <h2>2. Права и обязанности Оператора</h2>
                <p>2.1. Оператор имеет право:</p>
                <p>2.1.1. получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;</p>
                <p>2.1.2. требовать от субъекта персональных данных своевременного уточнения предоставленных персональных данных;</p>
                <p>2.1.3. самостоятельно определять состав и перечень мер, необходимых и достаточных для выполнения обязанностей по обеспечению защиты персональных данных, с учетом требований законодательства;</p>
                <p>2.1.4. поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено законодательством, на основании заключаемого с этим лицом договора;</p>
                <p>2.1.5. в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, предусмотренных законодательством.</p>
                <p>2.2. Оператор обязан:</p>
                <p>2.2.1. обрабатывать персональные данные в порядке, установленном применимым законодательством;</p>
                <p>2.2.2. рассматривать обращения субъекта персональных данных по вопросам обработки персональных данных и давать мотивированные ответы;</p>
                <p>2.2.3. сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в сроки, установленные законодательством;</p>
                <p>2.2.4. предоставлять субъекту персональных данных возможность безвозмездного доступа к его персональным данным;</p>
                <p>2.2.5. принимать меры по уточнению и (или) уничтожению ранее предоставленных персональных данных на основании законного и обоснованного обращения субъекта персональных данных;</p>
                <p>2.2.6. организовывать защиту персональных данных в соответствии с требованиями применимого законодательства;</p>
                <p>2.2.7. прекратить обработку персональных данных в случае отзыва субъектом этих персональных данных согласия на их обработку, а также при необходимости обезличить (уничтожить) такие персональные данные, если отсутствуют иные основания для таких действий с персональными данными, предусмотренные законодательными актами.</p>
                <h2>3. Права и обязанности субъектов персональных данных</h2>
                <p>3.1. Субъекты персональных данных имеют право:</p>
                <p>3.1.1. получать от Оператора информацию о своих правах, связанных со сбором, обработкой, распространением, предоставлением персональных данных;</p>
                <p>3.1.2. знакомиться со своими персональными данными, требовать внесения в них изменений, их блокирования или уничтожения с учетом особенностей, предусмотренных законодательством;</p>
                <p>3.1.3. отзывать согласие на обработку персональных данных путём направления соответствующего уведомления Оператору заказным почтовым отправлением или на электронный адрес <a href="mailto:elodjin@yandex.by;">elodjin@yandex.by;</a></p>
                <p>3.1.4. принимать предусмотренные законодательством меры по защите своих прав;</p>
                <p>3.1.5. получать информацию о предоставлении своих персональных данных третьим лицам;</p>
                <p>3.1.6. требовать прекращения сбора, обработки (за исключением обезличивания), распространения, предоставления персональных данных, в том числе распространенных персональных данных, и (или) их удаления при отсутствии оснований для их сбора, обработки, распространения, предоставления, предусмотренных Политикой и/или законодательными актами;</p>
                <p>3.1.7. требовать прекращения сбора, обработки (за исключением обезличивания), распространения, предоставления персональных данных и (или) удаления персональных данных, если они не являются необходимыми для заявленной цели их сбора, обработки, распространения, предоставления, а также в случае истечения соответствующего срока сбора, обработки, распространения, предоставления персональных данных;</p>
                <p>3.1.8. обжаловать действия (бездействие) и решения Оператора, связанные со сбором, обработкой, распространением, предоставлением своих персональных данных, в уполномоченный орган по защите прав субъектов персональных данных;</p>
                <p>3.1.9. осуществлять иные права, предусмотренные законодательством.</p>
                <p>3.2. Пользователи обязаны:</p>
                <p>3.2.1. полностью ознакомиться с Политикой до начала использования Приложения. При этом установка и/или запуск Приложения означает автоматическое согласие с Политикой и условиями обработки персональных данных Пользователей;</p>
                <p>3.2.2. дополнительно подтвердить принятие ими Политики путём указания в специальной графе кода, полученного в SMS от Оператора в рамках прохождения регистрации в Приложении;</p>
                <p>3.2.3. прекратить использование Приложения при несогласии с Политикой, в том числе в случае её последующего изменения Оператором в одностороннем порядке;</p>
                <p>3.2.4. предоставлять Оператору только достоверные персональные данные;</p>
                <p>3.2.5. предоставлять документы, содержащие персональные данные в объеме, необходимом для целей обработки;</p>
                <p>3.2.6. сообщать Оператору об уточнении (обновлении, изменении) персональных данных;</p>
                <p>3.2.7. получать предварительное согласие третьего лица на обработку в Приложении его персональных данных путем включения прилагаемой к Политике примерной формы согласия в договоры с третьими лицами либо в виде отдельно составленного документа;</p>
                <p>3.2.8. незамедлительно уведомлять Оператора об отзыве третьими лицами ранее данного согласия для принятия Оператором мер по удалению из Приложения персональных данных таких третьих лиц либо при необходимости их обезличивания.</p>
                <p>3.3. Пользователи гарантируют, что все предоставленные ими персональные данные являются достоверными и актуальными, а персональные данные третьих лиц предоставлены Оператору с согласия таких третьих лиц.</p>
                <p>3.4. Оператор не несёт ответственности за достоверность предоставленных Пользователями персональных данных, а также наличие у Пользователей согласия на предоставление персональных данных третьих лиц, его полноту и действительность.</p>
                <p>3.5. Пользователи несут всю полноту ответственности за размещение в Приложении персональных данных третьих лиц без их согласия в нарушение требований законодательства и (или) Политики.</p>
                <p>3.6. Персональные данные участников тематических групп в мессенджерах обрабатываются в порядке, аналогичном для персональных данных Пользователя.<br />Субъект персональных данных обязан до направления Оператору заявки на подключение к тематической группе в мессенджере полностью ознакомиться с настоящей Политикой.</p>
                <p>Субъект персональных данных, добровольно предоставляя свои персональные данные для подключения к чатам в мессенджерах, тем самым дает свое согласие на обработку указанных данных.</p>
                <p>Субъект персональных данных, который является участником чата в мессенджере, вправе в любое время отозвать данное согласие, а также потребовать удалить/блокировать его персональные данные, направив уведомление об отзыве по адресу <a href="mailto:elodjin@yandex.by">elodjin@yandex.by</a>.</p>
                <h2>4. Цели сбора персональных данных</h2>
                <p>4.1. Оператор обрабатывает только те персональные данные, которые необходимы для целей обеспечения функционирования Приложения, выполнения обязательств перед Пользователями и администрирования тематических групп в мессенджерах, к которым относятся:</p>
                <p>4.1.1. заключение с Пользователями любых договоров и их дальнейшее исполнение;</p>
                <p>4.1.2. предоставление Пользователям доступа к Приложению путем их регистрации в этом качестве;</p>
                <p>4.1.3. предоставление услуг и сервисов, входящих в состав Приложения;</p>
                <p>4.1.4. оказание консультативной и технической поддержки Пользователям при использовании Приложения;</p>
                <p>4.1.5. установление обратной связи с Пользователями, в том числе обработки их запросов и обращений, информирование о работе Приложения;</p>
                <p>4.1.6. проведение Оператором акций, опросов, интервью, тестирований и исследований с участием Пользователей;</p>
                <p>4.1.7. предоставление Пользователям с их согласия новостной рассылки и иных сведений, касающихся Приложения, а также информации о разработке Оператором новых продуктов и услуг, в том числе рекламного характера;</p>
                <p>4.1.8. контроль и улучшение качества услуг и сервисов, входящих в состав Приложения, обеспечение функционирования и безопасности Приложения;</p>
                <p>4.1.9. идентификация Пользователей, в том числе для подтверждения достоверности оставленных в Приложении сообщений (комментариев);</p>
                <p>4.1.10. предоставление доступа Пользователям на сайты или сервисы партнеров Оператора с целью получения продуктов, обновлений и услуг;</p>
                <p>4.1.11. использование предоставленных в мессенджере персональных данных при создании Оператором профиля Пользователя в Приложении.</p>
                <p>4.2. Непредоставление Пользователем необходимой информации, запрашиваемой в соответствующих секциях ввода информации Приложения или мессенджерах, может повлечь за собой невозможность предоставления Оператором Пользователю услуг.</p>
                <p>4.3. Обработка персональных данных осуществляется Оператором в соответствии со следующими принципами:</p>
                <p>4.3.1. обработка персональных данных осуществляется на законной и справедливой основе;</p>
                <p>4.3.2. обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных;</p>
                <p>4.3.3. не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</p>
                <p>4.3.4. обработке подлежат только те персональные данные, которые отвечают целям их обработки;</p>
                <p>4.3.5. содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Обрабатываемые персональные данные не являются избыточными по отношению к заявленным целям обработки;</p>
                <p>4.3.6. при обработке персональных данных должны быть обеспечены их точность, достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор должен принимать необходимые меры либо обеспечивать их принятие по удалению или уточнению неполных или неточных данных;</p>
                <p>4.3.7. хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных не дольше, чем этого требуют цели обработки персональных данных, если иной срок хранения персональных данных не установлен законодательством или договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено законодательством;</p>
                <p>4.3.8. обрабатываемые персональные данные по отдельному запросу Пользователя подлежат уничтожению либо обезличиванию в случае удаления учетной записи Пользователя (расторжения договора с Пользователем, в соответствии с которым осуществлялась обработка персональных данных).</p>
                <p>4.4. Оператор использует полученные персональные данные исключительно для целей, указанных в разделе 4 настоящей Политики.</p>
                <h2>5. Объём и категории обрабатываемых персональных данных. Категории субъектов персональных данных</h2>
                <p>5.1. Настоящая Политика устанавливает обязательства Оператора по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые предоставляет субъект персональных данных при регистрации в Приложении (тематической группе в мессенджере) и при совершении иных действий с использованием функционала Приложения, кроме случаев, когда субъект персональных данных выразил согласие на раскрытие своих персональных данным третьим лицам.</p>
                <p>5.2. Оператор обрабатывает персональные данные следующих субъектов персональных данных:</p>
                <p>5.2.1. Пользователей &ndash; физических лиц;</p>
                <p>5.2.2. представителей/работников Пользователей &ndash; юридических лиц;</p>
                <p>5.2.3. третьих лиц, давших согласие Пользователям на предоставление их персональных данных Оператору для обработки в Приложении;</p>
                <p>5.2.4. участников тематических групп в мессенджерах.</p>
                <p>5.3. К персональным данным, обрабатываемым Оператором, относятся:</p>
                <p>5.3.1. фамилия, имя и отчество;</p>
                <p>5.3.2. пол;</p>
                <p>5.3.3. дата рождения;</p>
                <p>5.3.4. контактный номер телефона;</p>
                <p>5.3.5. адрес электронной почты (e-mail);</p>
                <p>5.3.6. иная техническая информация об использовании Приложения, в частности: история просмотров и запросов в Приложении (лог-файлы), информация, получаемая автоматически в процессе использования Приложения (с использованием файлов cookies), данные о технических средствах и устройствах (IP-адрес, вид операционной системы, тип браузера, географическое положение, провайдер и пр.), информация, получаемая в процессе совершения Пользователем действий в Приложении (направленные запросы, комментарии, вопросы и пр.).</p>
                <p>5.4. Сведения, позволяющие идентифицировать Пользователя-юридическое лицо, такие как наименование, УНП, место нахождения, ОКПО и т.п., не относятся к персональным данным.</p>
                <p>5.5. Персональные данные, разрешенные к обработке в рамках настоящей Политики, предоставляются субъектом персональных данных добровольно по запросу Оператора при регистрации в Приложении путем заполнения регистрационной формы и (или) совершении иных действий, необходимых для создания учетной записи (профиля) Пользователя в Приложении, в процессе использования Приложения, а также при получении доступа к тематической группе в мессенджере.</p>
                <p>5.6. Оператор обеспечивает соответствие содержания и объёма обрабатываемых в Приложении персональных данных заявленным целям обработки и, в случае необходимости, принимает меры по устранению их избыточности по отношению к заявленным целям обработки.</p>
                <p>5.7. Обработка Оператором в Приложении специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни, не осуществляется, за исключением случаев, предусмотренных законодательством.</p>
                <p>5.8. Оператор не обрабатывает биометрические персональные данные в Приложении.</p>
                <p>5.9. Любые иные персональные данные, предоставленные Пользователями Оператору для обработки в Приложении и не перечисленные выше, подлежат надёжному хранению и нераспространению, за исключением случаев, предусмотренных законодательством или Политикой.</p>
                <h2>6. Порядок и условия обработки персональных данных</h2>
                <p>6.1. Обработка персональных данных в Приложении осуществляется Оператором в соответствии с требованиями законодательства Республики Беларусь, международных нормативных актов, а при обработке персональных данных граждан Российской Федерации &ndash; с учетом требований законодательства Российской Федерации.</p>
                <p>6.2. Обработка персональных данных в Приложении осуществляется Оператором с согласия субъектов персональных данных на обработку их персональных данных (далее &ndash; Согласие), а также без Согласия в случаях, предусмотренных законодательством.</p>
                <p>6.3. Согласие дается в порядке, предусмотренном пунктом 3.2 настоящей Политики. В предусмотренных законодательством случаях согласие оформляется в письменной форме.</p>
                <p>6.4. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия Согласия или отзыв Согласия субъектом персональных данных, а также выявление неправомерной обработки персональных данных.</p>
                <p>6.5. Под обработкой персональных данных понимаются все законные способы обработки, такие как сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, распространение третьим лицам (в том числе передача, предоставление, доступ), обезличивание, блокирование, уничтожение и любые другие действия (операции) с персональными данными в соответствии с законодательством.</p>
                <p>6.6. Обработка персональных данных осуществляется Оператором следующими способами:</p>
                <p>6.6.1. неавтоматизированная обработка персональных данных;</p>
                <p>6.6.2. автоматизированная обработка персональных данных с передачей полученной информации по информационно-телекоммуникационным сетям или без таковой;</p>
                <p>6.6.3. смешанная обработка персональных данных.</p>
                <p>6.7. Оператор обрабатывает персональные данные без ограничения срока, но в любом случае не дольше, чем этого требуют предусмотренные Политикой цели их обработки, если иной срок не установлен законодательством или договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.</p>
                <p>6.8. Источником получения Оператором персональных данных являются исключительно Пользователи и участники тематических групп в мессенджерах, их предоставляющие. Оператор не получает и не обрабатывает персональные данные из иных источников.</p>
                <p>6.9. К обработке персональных данных в Приложении и мессенджерах допускаются работники Оператора, в должностные обязанности которых входит разработка, сопровождение, поддержка и развитие Приложения, привлечение новых Пользователей либо иные функции, непосредственно касающиеся работы Приложения или исполнения обязательств перед Пользователями.</p>
                <p>6.10. Пользователь подтверждает и соглашается, что при оставлении в Приложении сообщений (комментариев), совершении иных действий в Приложении его персональные данные будут доступны для ознакомления всем Пользователям Приложения.</p>
                <p>6.11. Передача персональных данных органам дознания и следствия, иным уполномоченным органам государственной власти и организациям осуществляется Оператором в соответствии с требованиями законодательства.</p>
                <p>6.12. Оператор вправе поручить обработку персональных данных другому лицу, если иное не предусмотрено законодательством, на основании заключаемого с этим лицом договора.</p>
                <p>6.13. Оператор вправе передавать и распространять (в том числе трансгранично) персональные данные третьим лицам, в том числе лицу, владеющему сервером, на котором размещено Приложение.</p>
                <p>6.14. Оператор при обработке персональных данных принимает или обеспечивает принятие необходимых правовых, организационных и технических мер для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных, в частности:</p>
                <p>6.14.1. определяет угрозы безопасности персональных данных при их обработке в Приложении;</p>
                <p>6.14.2. применяет организационные и технические меры по обеспечению безопасности персональных данных при их обработке в Приложении, необходимые для выполнения требований к защите персональных данных, исполнение которых обеспечивает установленные законодательством уровни защищенности персональных данных;</p>
                <p>6.14.3. применяет прошедшие в установленном порядке процедуру оценки соответствия средства защиты информации, осуществляет техническую и криптографическую защиту персональных данных в порядке, устанавливаемом Оперативно-аналитическим центром при Президенте Республики Беларусь, в соответствии с классификацией информационных ресурсов (систем), содержащих персональные данные;</p>
                <p>6.14.4. проводит оценку эффективности принимаемых мер по обеспечению безопасности персональных данных до запуска Приложения в Российской Федерации;</p>
                <p>6.14.5. ведёт учёт машинных носителей персональных данных;</p>
                <p>6.14.6. обеспечивает регулярное резервное копирование информационной системы персональных данных из Приложения и хранение резервной копии в надёжном и защищённом месте;</p>
                <p>6.14.7. выявляет факты несанкционированного доступа к персональным данным и своевременно принимает необходимые эффективные меры реагирования и предотвращения в будущем аналогичных фактов;</p>
                <p>6.14.8. незамедлительно восстанавливает персональные данные, модифицированные или уничтоженные вследствие несанкционированного доступа к ним;</p>
                <p>6.14.9. устанавливает правила доступа к персональным данным, обрабатываемым в Приложении, а также обеспечивает регистрацию и учёт всех действий, совершаемых с персональными данными в Приложении;</p>
                <p>6.14.10. осуществляет непрерывный контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности Приложения;</p>
                <p>6.14.11. принимает иные меры, предусмотренные законодательством.</p>
                <p>6.15. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети &laquo;Интернет&raquo;, Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в Федеральном законе от 27 июля 2006 года № 152-ФЗ &laquo;О персональных данных&raquo;.</p>
                <p>6.16. До начала осуществления трансграничной передачи персональных данных Оператор обязан убедиться в том, что иностранным государством, на территорию которого осуществляется передача персональных данных, обеспечивается адекватная защита прав субъектов персональных данных.</p>
                <h2>7. Разрешение споров</h2>
                <p>7.1. До обращения в суд с иском по спорам, возникающим из отношений между субъектом персональных данных и Оператором, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
                <p>7.2. Получатель претензии в течение 15 календарных дней со дня получения претензии письменно уведомляет заявителя претензии о результатах ее рассмотрения.</p>
                <p>7.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с законодательством Республики Беларусь.</p>
                <h2>8. Заключительные положения</h2>
                <p>8.1. Все вопросы, касающиеся обработки Оператором персональных данных в Приложении и не регламентированные Политикой, регулируются законодательством Республики Беларусь. При обработке персональных данных граждан Российской Федерации Оператор учитывает требования законодательства Российской Федерации.</p>
                <p>8.2. Оператор имеет право вносить изменения в Политику. При внесении изменений в актуальной редакции Политики указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента её размещения в Приложении, если иное не предусмотрено новой редакцией Политики.</p>
                <p>8.3. Пользователь обязуется самостоятельно контролировать наличие изменений в Политике путем просмотра всплывающих (push) уведомлений в Приложении.</p>
                <p>8.4. Все предложения или вопросы по настоящей Политике следует направлять на электронный адрес Оператора <a href="mailto:elodjin@yandex.by">elodjin@yandex.by</a>.</p>
                <div className="Policy-section-2">
                    <div>
                        <div>
                            Приложение
                </div>
                        <div>
                            к Политике Общества с ограниченной ответственностью «Элоджин»
                </div>
                        <div>
                            в отношении обработки персональных данных в приложении «ALodging»
                </div>
                    </div>
                    <h2>Примерная форма согласия на обработку персональных данных (вариант 1)</h2>
                    <p>Настоящим я свободно, своей волей и в своем интересе даю свое согласие Арендодателю на обработку своих персональных данных, указанных в настоящем Договоре, а также на передачу Арендодателем моих персональных данных Обществу с ограниченной ответственностью &laquo;Элоджин&raquo;, являющемуся юридическим лицом по законодательству Республики Беларусь, зарегистрированному Минским городским исполнительным комитетом 22 апреля 2019 года, УНП 193244728, расположенному по адресу: Республика Беларусь, г. Минск, ул. Некрасова, д. 88, пом. 1-5 (далее &ndash; &laquo;Оператор&raquo;), для обработки в принадлежащем ему программном обеспечении ALodging (далее &ndash; &laquo;Приложение&raquo;), а также соглашаюсь с политикой Оператора в отношении обработки персональных данных в Приложении (далее &ndash; Политика).</p>
                    <p>Под персональными данными я понимаю любую информацию, относящуюся ко мне как к субъекту персональных данных, в том числе, но не исключительно, мои имя, фамилию и отчество, дату рождения, место жительства, контактные данные (телефон и электронная почта), платежные реквизиты. Под обработкой персональных данных я понимаю сбор, систематизацию, накопление, уточнение, обновление, изменение, использование, распространение, передачу (включая трансграничную) третьим лицам, обезличивание, блокирование, уничтожение, бессрочное хранение, а также любые другие действия (операции) с персональными данными, предусмотренные законодательством.</p>
                    <p>Обработка моих персональных данных осуществляется исключительно в целях исполнения настоящего Договора, а также предоставления услуг и сервисов, входящих в состав Приложения, в том числе оставления отзыва (оценки) о моем пребывании в арендуемом помещении.</p>
                    <p>Датой выдачи согласия на обработку моих персональных данных является дата заключения настоящего Договора.</p>
                    <p>Обработка моих персональных данных может осуществляться с помощью средств автоматизации и/или без использования средств автоматизации в соответствии с законодательством, а также Политикой.</p>
                    <p>Арендодатель и Оператор принимают необходимые правовые, организационные и технические меры или обеспечивают их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных, а также принимают на себя обязательство сохранения конфиденциальности персональных данных, за исключением случаев правомерной передачи (распространения) персональных данных, выраженных в настоящем согласии.</p>
                    <p>Персональные данные обрабатываются до момента письменного отзыва настоящего согласия. Арендодатель обязуется незамедлительно уведомить Оператора об отзыве настоящего согласия для принятия мер по удалению или обезличиванию обрабатываемых в Приложении персональных данных.</p>
                    <p>Я могу в любой момент отозвать настоящее согласие, направив письменное уведомление на адрес: _______________________ или на электронную почту ________________ с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</p>
                    <p>Я ознакомлен(а), что:</p>
                    <ul>
                        <li>срок действия настоящего согласия на обработку моих персональных данных является неограниченным;</li>
                        <li>согласие может быть отозвано мною на основании письменного заявления в произвольной форме.</li>
                    </ul>
                    <p>&lt;Фамилия, имя, отчество, подпись&gt;</p>
                    <h2>Примерная форма согласия на обработку персональных данных (вариант 2)</h2>
                    <p>Настоящим я свободно, своей волей и в своем интересе даю свое согласие Арендодателю на обработку своих персональных данных, указанных в настоящем Договоре, а также на передачу Арендодателем моих персональных данных для обработки Обществу с ограниченной ответственностью &laquo;Элоджин&raquo;, являющемуся юридическим лицом по законодательству Республики Беларусь, зарегистрированному Минским городским исполнительным комитетом 22 апреля 2019 года, УНП 193244728, расположенному по адресу: Республика Беларусь, г. Минск, ул. Некрасова, д. 88, пом. 1-5 (далее &ndash; &laquo;Оператор&raquo;), для обработки в принадлежащем ему программном обеспечении ALodging (далее &ndash; &laquo;Приложение&raquo;), а также соглашаюсь с политикой Оператора в отношении обработки персональных данных в Приложении (далее &ndash; Политика).</p>
                    <p>Под персональными данными я понимаю любую информацию, относящуюся ко мне как к субъекту персональных данных, в том числе, но не исключительно, мои имя, фамилию и отчество, дату рождения, место жительства, контактные данные (телефон и электронная почта), платежные реквизиты. Под обработкой персональных данных я понимаю сбор, систематизацию, накопление, уточнение, обновление, изменение, использование, распространение, передачу (включая трансграничную) третьим лицам, обезличивание, блокирование, уничтожение, бессрочное хранение, а также любые другие действия (операции) с персональными данными.</p>
                    <p>Обработка моих персональных данных осуществляется исключительно в целях исполнения настоящего Договора, а также предоставления сервисов, входящих в состав Приложения, в том числе оставления отзыва (оценки) о моем пребывании в арендуемом помещении. Датой выдачи согласия на обработку моих персональных данных является дата заключения настоящего Договора. Срок действия настоящего согласия на обработку моих персональных данных является неограниченным. Персональные данные обрабатываются до момента письменного отзыва настоящего согласия. Я могу в любой момент отозвать настоящее согласие, направив письменное уведомление на адрес ________________ или на электронную почту ________________ с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</p>
                    <p>&lt;Фамилия, имя, отчество, подпись&gt;</p>
                </div>
            </div>
        )
    }
}

export default Policy