import React from 'react'
import Overlay from '../Overlay/Overlay'
import './ModalMsg.css'

export default (props) => {
    return (
        <>
            <Overlay color="#1010109d" />
            <div className="ModalMsg">
                <div className="close-button" onClick={props.onClick}>
                    <div className="close-line-1"></div>
                    <div className="close-line-2"></div>
                </div>
                <div>{props.children}</div>
            </div>
        </>
    )
}
