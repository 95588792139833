import React, { Component } from 'react'
import EripLogo from './erip-logo.jpg'

class Payment extends Component {

    componentDidMount() {
        document.title = 'OOO «ЭЛОДЖИН» - Виды и способы оплаты приложения «ALODGING» - alodging.by'
    }

    render() {
        return (
            <div>
                <h1>Способы оплаты</h1>
                <p>Оплату за использование информационной базы ALodging можно произвести банковской платёжной картой через систему <a href="www.webpay.by">WebPay</a>.</p>
                <p>Безопасный сервер WEBPAY устанавливает шифрованное соединение по защищенному протоколу TLS
                    и конфиденциально принимает от клиента данные его платёжной карты (номер карты, имя держателя,
            дату окончания действия, и контрольный номер банковской карточке CVC/CVC2).</p>
                <p>После совершения оплаты с использованием банковской карты необходимо сохранять полученные
                    карт-чеки (подтверждения об оплате) для сверки с выпиской из карт-счёта (с целью подтверждения
            совершённых операций в случае возникновения спорных ситуаций).</p>
                <p>В случае, если после оплаты вы не можете получить доступ к базе ALodging, вам необходимо
                    обратиться в службу технической поддержки по телефону +375 (33) 371-11-10 или e-mail
            rysergei@yandex.ru. Менеджер вас проконсультирует.</p>
                <img src={EripLogo} width="100px" alt="Логотип ЕРИП"></img>
                <h2>Оплата через ЕРИП</h2>
                <p>Оплату через ЕРИП вы можете произвести в любом банке:</p>
                <ul>
                    <li>интернет-банкинге</li>
                    <li>мобильном банкинге</li>
                    <li>инфокиоске</li>
                    <li>банкомате</li>
                    <li>кассе и т.д.</li>
                </ul>
                <p>Совершить оплату можно с использованием:</p>
                <ul>
                    <li>наличных денежных средств</li>
                    <li>любых электронных денег</li>
                    <li>банковских платежных карточек</li>
                </ul>
                <h3>Для проведения платежа необходимо:</h3>
                <ol>
                    <li>Выбрать <i>Пункт &laquo;Система &laquo;Расчет&raquo; (ЕРИП) -> Интернет-магазины/сервисы -> A-Z
                        Латинские домены -> A -> Alodging.by</i></li>
                    <li>Для оплаты услуги ввести номер заказа</li>
                    <li>Ввести сумму платежа (если не указана)</li>
                    <li>Проверить корректность информации</li>
                    <li>Совершить платеж</li>
                </ol>
                <p>Если вы осуществляете платеж в кассе банка, пожалуйста, сообщите кассиру о необходимости проведения
                платежа через систему &laquo;Расчет&raquo; (ЕРИП).</p>
            </div>
        )
    }
}

export default Payment