import React, { Component } from 'react'
import MainMenu from '../../components/MainMenu/MainMenu'
import SocialLinks from '../../components/UI/SocialLinks/SocialLinks'
import './Layout.css'

class Layout extends Component {
    render() {
        return (
            <div className="Layout">
                <header>
                    <div className="container">
                        <div className="box-1">
                            <div className="logo"></div>
                            <MainMenu />
                        </div>
                    </div>
                </header>
                <main>
                    <div className="container">{this.props.children}</div>
                </main>
                <footer>
                    <div className="container">
                        <div className="box-0">
                            <div className="company-name">
                                ООО &laquo;Элоджин&raquo;
                            </div>
                            <SocialLinks />
                        </div>
                        <div className="box-2">
                            <div>
                                <address>
                                    Республика Беларусь, 220040, г.Минск, ул.
                                    Некрасова, 88, помещение 1-5
                                </address>
                                <div>УНП 193244728</div>
                                <div>Тел. +375 (33) 371-11-10</div>
                                <div>
                                    Email:{' '}
                                    <a href="mailto:elodjin@yandex.by">
                                        elodjin@yandex.by
                                    </a>
                                </div>
                                <div>Режим работы: ПН - ПТ с 9:00 до 18:00</div>
                            </div>
                            <div>
                                <div>Расчетный счет</div>
                                <div>BY15PJCB30124212491000000933</div>
                                <div>БИК PJCBBY2X</div>
                                <div>"Приорбанк" ОАО</div>
                            </div>
                        </div>
                        <div className="pay-logo"></div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Layout
