import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../components/UI/Input/Input'
import ModalMsg from '../../components/UI/ModalMsg/ModalMsg'
import './SignUp.css'

class SignUp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mobileOS: 'Android',
            city: '',
            unp: '',
            fio: '',
            tel: '',
            email: '',
            link: '',
            comment: '',
            formMsg: '',
            formMsgClass: '',
            showModalMsg: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    render() {
        return (
            <div className="SignUp">
                <h1>Регистрационная форма</h1>
                <p>
                    Регистрация и авторизация представляет собой совокупность мероприятий, при помощи которых Исполнитель может убедиться, что лицо, выразившее
                    желание использовать Сервис, соответствует критериям, установленным для Заказчика. Авторизация в Сервисе является бесплатной и добровольной.
                </p>
                <p>
                    Чтобы получить доступы к Сервису, пожалуйста заполните форму регистрации и нажмите кнопку «ОТПРАВИТЬ». Затем в ближайшие 24 часа с вами
                    свяжется наш сотрудник по указанным контактным данным для прохождения процедуры верификации. После чего вы сможете самостоятельно
                    авторизоваться в Сервисе.
                </p>
                <p>
                    Если остались вопросы, напишите администратору в Viber, WhatsApp, Telegram +375 (33) 371-11-10 или воспользуйтесь данной формой для обратной
                    связи с нами:
                </p>
                <form id="regForm" onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <label htmlFor="select-mobileOS">Укажите OC вашего мобильного устройства</label>
                        <select id="select-mobileOS" name="mobileOS" value={this.state.mobileOS} onChange={this.handleChange}>
                            <option value="Android">Android</option>
                            <option value="iOS">iOS</option>
                            <option value="Windows Phone">Windows Phone</option>
                        </select>
                    </div>
                    <Input label="В каком городе сдаете жилье по суткам" name="city" value={this.state.city} onChange={this.handleChange} />
                    <Input label="УНП (ИНН, ЕГРПОУ и т.п. )" name="unp" value={this.state.unp} onChange={this.handleChange} />
                    <Input label="ФИО" name="fio" value={this.state.fio} onChange={this.handleChange} />
                    <Input label="Телефон в формате +375291223344" name="tel" value={this.state.tel} onChange={this.handleChange} />
                    <Input type="email" label="E-mail" name="email" value={this.state.email} onChange={this.handleChange} />
                    <Input label="Ссылка на свое объявление по сдаче жилья" name="link" value={this.state.link} onChange={this.handleChange} />
                    <div className="form-row">
                        <label htmlFor="textarea-comment">Комментарий (если хотите что-то дополнить или есть вопросы, напишите)</label>
                        <textarea id="textarea-comment" name="comment" value={this.state.comment} onChange={this.handleChange} />
                    </div>
                    <div className={'form-msg ' + this.state.formMsgClass}>{this.state.formMsg}</div>
                    <div className="policy-agreement">
                        Нажав кнопку "Отправить" я соглашаюсь на обработку персональных данных на условиях{' '}
                        <Link to="/politika-konfidencialnosti">Политики конфиденциальности</Link>
                    </div>
                    <button type="submit">Отправить</button>
                </form>
                <div>
                    <i className="far fa-handshake"></i>
                    <Link to="/dogovor">Договор</Link>
                </div>
                <div>
                    <i className="fas fa-user-shield"></i>
                    <Link to="/politika-konfidencialnosti">Политика конфиденциальности</Link>
                </div>
                {this.state.showModalMsg ? (
                    <ModalMsg onClick={() => this.setState({ showModalMsg: false })}>
                        <p style={{ textAlign: 'center' }}>
                            Ваше сообщение отправлено.
                            <br />
                            Спасибо, что обратились к нам!
                            <br />
                            Ожидайте ответа нашего сотрудника.
                        </p>
                    </ModalMsg>
                ) : null}
            </div>
        )
    }

    componentDidMount() {
        document.title = 'OOO «ЭЛОДЖИН» - Регистрационная форма приложения «ALODGING» - alodging.by'
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            formMsg: ''
        })
    }

    handleSubmit(event) {
        event.preventDefault()

        if (!this.state.fio) {
            this.setState({
                formMsg: 'Необходимо внести ФИО',
                formMsgClass: 'error'
            })
            return
        }

        if (!this.state.email) {
            this.setState({
                formMsg: 'Необходимо внести email',
                formMsgClass: 'error'
            })
            return
        }

        const formData = new FormData(document.getElementById('regForm'))

        let responseStatus

        fetch('https://alodging.by/mailer.php', {
            method: 'POST',
            body: formData
        })
            .then((response) => {
                responseStatus = response.status
                return response.text()
            })
            .then((result) => {
                if (responseStatus === 200) {
                    this.setState({
                        mobileOS: 'Android',
                        city: '',
                        unp: '',
                        fio: '',
                        tel: '',
                        email: '',
                        link: '',
                        comment: '',
                        showModalMsg: true
                    })
                } else {
                    this.setState({
                        formMsg: result,
                        formMsgClass: 'error'
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    formMsg: error.message,
                    formMsgClass: 'error'
                })
            })
    }
}

export default SignUp
