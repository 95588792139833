import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Home.css'

class Home extends Component {
    componentDidMount() {
        document.title =
            'Белый • Черный список клиентов для ИП и хозяев посуточного жилья, проверка арендаторов и добавление отзывов на гостей. Сайт OOO «ЭЛОДЖИН» - ALodging.by'
    }

    render() {
        return (
            <div className="Home">
                <h1>Об услуге</h1>
                <p>
                    ALodging. Белый & Черный список. База отзывов о клиентах,
                    снимающих жильё посуточно.
                </p>
                <p>
                    Предоставление доступа к базе данных отзывов максимальным
                    возможным количеством способов, в том числе:
                </p>
                <ol>
                    <li>
                        через программный комплекс, устанавливаемый на
                        персональном компьютере
                    </li>
                    <li>
                        через мобильное приложение, устанавливаемое на
                        смартфоне/ планшете
                    </li>
                    <li>посредством доступа с использованием веб-браузера</li>
                    <li>иными способами</li>
                </ol>
                <p>Основные функции:</p>
                <ul>
                    <li>автоматическая проверка входящих звонков</li>
                    <li>автоматическая проверка исходящих звонков</li>
                    <li>
                        возможность получить полный список отзывов по номеру
                        телефона, УНП и наименованию организации
                    </li>
                    <li>возможность добавить отзыв в базу данных</li>
                </ul>
                <div className="Home-section">
                    <iframe
                        src="https://www.youtube.com/embed/JWnLhBH6Ny4"
                        title="ALODGING - добавление отзывов о гостях и организациях арендующих жильё на сутки"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <p>
                    Доступ только для коллег-посуточников из тематической Viber
                    группы. Для подключения к базе ALodging заполните{' '}
                    <Link to="/registracionnaya-forma">
                        регистрационную форму
                    </Link>
                </p>
                <div className="Home-section">
                    <p>Мобильное Android приложение</p>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.alodging.informer.owner&hl=ru&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        title="Черный & Белый список жильцов арендующих квартиры на сутки - мобильное приложение ALodging"
                    >
                        <img
                            width="200px"
                            alt="Доступно в Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png"
                        />
                    </a>
                </div>
                <div className="doc-link">
                    <i className="far fa-handshake"></i>
                    <Link to="/dogovor">Договор</Link>
                </div>
                <div className="doc-link">
                    <i className="fas fa-user-shield"></i>
                    <Link to="/politika-konfidencialnosti">
                        Политика конфиденциальности
                    </Link>
                </div>
            </div>
        )
    }
}

export default Home
