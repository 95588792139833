import React, { useState, useEffect } from 'react'
//import Overlay from '../UI/Overlay/Overlay'
import TryFree from './TryFree/TryFree'
import './Tariff.css'

function Tariff() {
    useEffect(() => {
        document.title = 'OOO «ЭЛОДЖИН» - Тарифы и основные функции приложения «ALODGING» - alodging.by'
    })

    const [renderModal, setRenderModal] = useState(false)

    return (
        <>
            <div className="Tariff">
                <h1>Тарифы</h1>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Легкий</th>
                            <th>Базовый</th>
                            <th>Полный</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Полный доступ к базе отзывов</td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>Количество телефонных номеров включенных в тариф</td>
                            <td>1</td>
                            <td>2</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>Возможность добавлять свои отзывы</td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>Поиск отзывов по номеру телефона</td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>Поиск отзывов по УНП и названию организации</td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr className="android-opt">
                            <td className="android-opt" colSpan="4">
                                <h3>Возможности мобильного приложения на Android</h3>
                            </td>
                        </tr>
                        <tr className="android-opt">
                            <td>Проверка входящих звонков</td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr className="android-opt">
                            <td>Проверка исходящих звонков</td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr className="android-opt">
                            <td>Быстрое добавление отзывов с автозаполнением</td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr className="android-opt">
                            <td>Быстрый просмотр отзывов по номеру телефона после разговора/звонка</td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr className="android-opt">
                            <td>Опция &laquo;Автозакрытие информации с рейтингом при входящем звонке&raquo;</td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr className="android-opt">
                            <td>Опция &laquo;Не отображать информацию с рейтингом, если номер в контактной книге&raquo;</td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr className="android-opt">
                            <td>Детализация рейтинга</td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-times"></i>
                            </td>
                            <td>
                                <i className="fas fa-check"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>По рекомендации или одновременное подключение 2 пользователей на период 12 месяцев (скидка 50%)</td>
                            <td>3.19 byn/мес</td>
                            <td>9.10 byn/мес</td>
                            <td>11.38 byn/мес</td>
                        </tr>
                        <tr>
                            <td>Период оплаты 12 месяцев (скидка 30%)</td>
                            <td>4.46 byn/мес</td>
                            <td>12.74 byn/мес</td>
                            <td>15.93 byn/мес</td>
                        </tr>
                        <tr>
                            <td>Период оплаты 3 месяца (скидка 10%)</td>
                            <td>5.73 byn/мес</td>
                            <td>16.38 byn/мес</td>
                            <td>20.48 byn/мес</td>
                        </tr>
                        <tr>
                            <td>Период оплаты 1 месяц</td>
                            <td>6.37 byn/мес</td>
                            <td>18.20 byn/мес</td>
                            <td>22.75 byn/мес</td>
                        </tr>
                        <tr>
                            <td>Стоимость подключения дополнительного номера</td>
                            <td>5.20 byn/мес</td>
                            <td>3.90 byn/мес</td>
                            <td>3.90 byn/мес</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    <button className="tryfree-button" onClick={() => setRenderModal(true)}>
                        Попробовать бесплатно
                    </button>
                </p>
            </div>

            {renderModal === true ? (
                <TryFree
                    onClick={() => {
                        setRenderModal(false)
                    }}
                />
            ) : null}
        </>
    )
}

export default Tariff
